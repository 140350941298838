/* requires:
polyfill.js
*/

// Dropdown Select Toggle
const activeClass = "is-active";
const dropdownCollection = document.querySelectorAll(
  ".dropdown-list span.dropdown"
);
for (let dropdownToggle of dropdownCollection) {
  dropdownToggle.addEventListener("click", function () {
    //console.log(dropdownToggle.classList);
    if (!dropdownToggle.classList.contains(activeClass)) {
      const el = document.querySelectorAll(".dropdown-list span.dropdown");
      for (let i = 0; i < el.length; i++) {
        el[i].classList.remove(activeClass);
      }
      dropdownToggle.classList.toggle(activeClass);
    } else if (dropdownToggle.classList.contains(activeClass)) {
      dropdownToggle.classList.remove(activeClass);
    }
  });
}

document.addEventListener(
  "click",
  function (e) {
    // Dropdown Select Toggle
    const el = document.querySelectorAll(".dropdown-list span.dropdown");
    const el2 = document.querySelectorAll(
      ".dropdown-list span.submenu.level-2"
    );
    const event = e ? e : window.event;
    const eventElement = event.target;
    if (!eventElement.closest(".dropdown-list")) {
      //console.log(event_element.closest('.dropdown-list'));
      for (let i = 0; i < el.length; i++) {
        el[i].classList.remove(activeClass);
      }
      for (let i = 0; i < el2.length; i++) {
        el2[i].classList.remove(activeClass);
      }
    }
    if (
      eventElement.closest(".dropdown-list") &&
      [...eventElement.classList].includes("level-2")
    ) {
      eventElement.classList.toggle(activeClass);
    }
  },
  false
);
